import {
	BrowserRouter as Router,
	Routes,
	Route
      } from "react-router-dom";

import Home from "../views/home"
import Survey from "../views/survey";
import ThankYou from "../views/thankyou";

const App = ()=>{
	return (
		<div className="App">
			<div className="Container">
			<Router>
				<Routes>
					<Route path="/survey" element={<Survey/>}/>
					<Route path="/thankyou" element={<ThankYou/>}/>
					<Route path="/" element={<Home/>}/>
				</Routes>
			</Router>
			</div>	
		</div>
	)
}

export default App