import classNames from "classnames"

import './styles.css'

const Button = ({Outlined, OnClick, Disabled, children})=>{
	return(
		<button disabled={Disabled} className={classNames("btn", {"outlined": Outlined})} onClick={OnClick}>{children}</button>
	)
}

export default Button