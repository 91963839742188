import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../components/button"
import HeroImage from "../../components/heroImage";

import Image2 from '../../assets/img/image2.png'

import './styles.css'
import { SubmitSurveyAnswer } from "../../utils/clientActions";


const SurveyOptions = [
	"Harmony",
	"Radiance",
	"Nothing",
	"Indifference",
	"Resentment",
	"Service",
	"Joy",
	"Prophecy",
	"Obstacles",
	"Energy",
	"Justice",
	"Skepticism",
	"Beauty",
	"Solidarity",
	"Knowledge"
]


const Survey = ()=>{
	const [selection, setSelection] = useState(-1)
	let navigate = useNavigate();

	const UpdateSelection = (idx)=>{
		setSelection(idx)
	}

	const SubmitSelection = ()=>{
		SubmitSurveyAnswer(SurveyOptions[selection]).then(()=>{
			navigate('/thankyou')
		})
	}

	window.history.replaceState(null, "", "/")
	window.scrollTo(0, 0)

	return (
		<div className="survey">
			<div className="Hero">
				<HeroImage Image={Image2}/>
			</div>
			<div className="SurveyPrompt">
				<div>
					What would you like to contribute to a Muslim future?
				</div>
			</div>
			<div className="RadioSelection">
				{SurveyOptions.map((e,i)=>(
					<div>
						<input type="radio" id={e} checked={i==selection} onChange={()=>{UpdateSelection(i)}}/>
						<label for={e}>{e}</label>
					</div>
				))}
			</div>
			<div className="CTA">
				<Button OnClick={()=>{SubmitSelection()}} Disabled={selection==-1}>Submit</Button>
			</div>
		</div>
	)
}

export default Survey