import './style.css'

const HeroImage = ({Image, Alt})=>{
	return (
		<div className="ImageContainer">
			<img src={Image} alt={Alt}/>
		</div>
	)
}

export default HeroImage