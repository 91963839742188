import HeroImage from "../../components/heroImage";

import Image3 from "../../assets/img/image3.png"
import Logo from "../../assets/img/LOGO_MIPSTERZ.png"

import './styles.css'

const ThankYou = ()=>{

	window.history.replaceState(null, "", "/")
	window.scrollTo(0, 0)

	return (
		<div className="thankyou">
			<div className="Hero">
				<HeroImage Image={Image3}/>
			</div>

			<div className="header">
				Thank you!
			</div>
			<hr className="line" size="2" width="85%" color="#00A5BB"></hr>
			<div className="info">
				<p>Your response will be submitted to <b><i>The Mirage</i></b>. </p>
				<p><b><i>The Mirage</i></b> and ALHAMDU | MUSLIM FUTURISM exhibition were created by MIPSTERZ, an arts and culture collective for emerging Muslim creatives. To learn more about our work and the larger ALHAMDU project, please visit <a href="https://www.mipsterz.com/">mipsterz.com.</a></p>
				<p className="quote">“MIPSTERZ is an arts and culture collective for emerging Muslim creatives. We enable and amplify Muslim creative voices by presenting and producing original works for general audiences.“</p>
			</div>
			<div className="Title">
				<div>
					<h1><b><i>The Mirage</i></b></h1>
				</div>
				<div>
					<h2>ALHAMDU | MUSLIM FUTURISM</h2>
				</div>
			</div>
			<div className="Logo">
				<div className="LogoContainer">
					<HeroImage Image={Logo}/>
				</div>
			</div>
		</div>
	)
}

export default ThankYou