export const SubmitSurveyAnswer = (surveyAnswer)=>{
	return fetch( 'https://c6d3020f2j.execute-api.us-east-1.amazonaws.com/production/response', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		      },
		body: JSON.stringify({response: surveyAnswer})
	} ).then((response)=>{
		return response.json()
	})
}