import { useNavigate } from "react-router-dom";

import Button from "../../components/button"
import HeroImage from "../../components/heroImage"
import Image1 from '../../assets/img/image1.png'

import './styles.css'

const Home = ()=>{
	let navigate = useNavigate();
	return (
		<div className="home">
			<div className="Hero">
				<HeroImage Image={Image1}></HeroImage>
			</div>

			<div className="Title">
				<div>
					<h1>The Mirage</h1>
				</div>
				<div>
					<h2>ALHAMDU | MUSLIM FUTURISM</h2>
				</div>
			</div>
			
			<div className="Description">
				
				<p>We submit to you a joyous, vibrant vision of liberation. A future where our dignity, flourishing, and imaginations as Muslims are actualized. We dream of worlds and communities where we exist boldly, proudly, and unapologetically.</p>
 
 				<p>There should be no ambiguity in our call—a joyous Muslim future begins when we resist against anti-Black racism, Islamophobia / xenophobia, orientalism, homo-trans phobias, sexism, ableism, settler colonialism, nationalism, imperialism, militarism, supremacy-nativism, and everything in between.</p>
  
				<p>The chronic suffering of yesterday and the compounded acute traumas of each day are constant affronts to the Muslim spirit—restricting us from conceiving and building toward a utopia of our own. May the process of boldly asserting our existence disrupt the hegemonic powers of today and serve as a portal to another planet where our joy is championed, our spirits are revitalized, and our commitments to flourishing and justice are re-energized.</p>
 
 				<p>We are all a part of this future. We all have a role to play. It is up to you to decide how this future takes form.</p>

			</div>
			<div className="CTA">
				<Button Outlined OnClick={()=>{navigate('/survey')}}>Next</Button>
			</div>
		</div>
	)
}

export default Home